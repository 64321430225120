import React from 'react';
import './control.css'

import { Button, TextField } from '@mui/material';
import { appURL } from '../constants';
import HintSystem from '../hintBar';

function Controls
    ({
        insert,
        setInsert,
        remove,
        setRemove,
        edit,
        setEdit,
        newEntry,
        setNewEntry,
        selectedEntries,
        setSelectedEntries,
        totalSongs,
        setTotalSongs,
        search,
        setSearch,
        searchBar,
        setSearchBar,
        login,
        setLogin
    }) {

    const inputDefault = {
        songName: '',
        singer: '',
        language: '',
        style: '',
        mark: '',
    }

    const hintRef = React.useRef();

    // We need to separate these two because in the Insert 
    // function we need to clear the input field
    const toggleButtonInsert = () => {
        setInsert((prev) => !prev);
        console.log(`control.js: insert status: ${insert}`)
        if (!insert) {
            setEdit(false);
            setRemove(false);
            setSearch(false);
        }
        setNewEntry(inputDefault);
    };


    // The remove button -
    // We also put the track for selected entry here
    // State to track the selected song entry for removal
    // const [selectedEntry, setSelectedRemove] = useState(null);
    // The function to update the index
    const toggleButtonRemove = () => {
        setRemove((prev) => !prev);
        if (!remove) {
            setEdit(false);
            setInsert(false);
            setSearch(false);
        }
        setSelectedEntries([]);
    }

    const toggleButtonEdit = () => {
        setEdit((prev) => !prev);
        if (!edit) {
            setInsert(false);
            setRemove(false);
            setSearch(false);
        }
        setNewEntry(inputDefault);
        setSelectedEntries([]);
    }

    // TODO: Add random song name copy to the clipboard.
    const toggleRandomSong = async () => {
        const searchQuery = search ? encodeURIComponent(searchBar) : '';
        const url = `${appURL}/songList/rand?query=${searchQuery}`
        try {
            // fetch a random song
            const response = await fetch(url);
            if (response.ok) {
                const result = await response.json();
                console.log(result.message);
                if (result.message === "ERRORERRORNOTFOUND") {
                    // If there is some error happending here
                    // TODO: Add a notice system here.
                    hintRef.current.showHint("No song under current searching criteria");
                } else {
                    // Elsewise copy the song name to clipboard
                    navigator.clipboard.writeText(`点歌 ${result.message}`)
                        .then(() => console.log(`${result.message} copied to clipboard`))
                        .catch((err) => console.error('Failed to copy text: ', err));
                    hintRef.current.showHint(`(σ°∀°)σ..:*☆「${result.message}」已经复制到你的剪切板辣~`)
                }
            }
        } catch (error) {
            console.error('Error: control.js ', error);
        }
    }

    // The confirm button function.
    // Function to handle deletion of selected songs
    const handleDeleteSelected = async () => {
        try {
            // Send delete requests for each selected entry
            const deletePromises = selectedEntries.map((id) =>
                fetch(`${appURL}/songList/delete/${id}`, { // fetch(`${appURL}:3001/songList/delete/${id}`,
                    method: 'DELETE',
                })
            );
            // Wait for all delete operations to complete
            const results = await Promise.all(deletePromises);

            // Filter out the successful deletions
            const successfulDeletes = results.filter((res) => res.ok);

            if (successfulDeletes.length > 0) {
                setSelectedEntries([]); // Clear selections
                setTotalSongs(totalSongs - successfulDeletes.length);
                console.log('Songs deleted successfully');
            } else {
                console.error('No songs were deleted. Check the backend response.');
            }
        } catch (error) {
            console.error('Error deleting songs:', error);
        }
    };

    const toggleButtonSearch = () => {
        // Make sure we are not messing up with anything.
        setInsert(false);
        setRemove(false);
        setEdit(false);
        setSearch((prev) => !prev);
    }

    return (
        <div className="controls">
            {(login !== 2) && (
                <Button
                    onClick={toggleRandomSong}
                    color="primary"
                    className='search-button'
                >
                    🎧随便听听🎲
                </Button>
            )}
            {(login === 2) && (
                <>
                    <Button
                        onClick={() => toggleButtonInsert()}
                        variant={insert ? 'contained' : 'outlined'}
                        color="primary"
                        className={insert ? 'pressed' : ''}
                    ></Button>

                    <Button
                        onClick={() => toggleButtonRemove()}
                        variant={remove ? 'contained' : 'outlined'}
                        color="secondary"
                        className={remove ? 'pressed' : ''}
                    >
                    </Button>

                    {remove && (
                        <Button
                            onClick={handleDeleteSelected}
                            variant="contained"
                            color="error"
                        >
                        </Button>
                    )}

                    <Button
                        onClick={() => toggleButtonEdit()}
                        variant={edit ? 'contained' : 'outlined'}
                        color="default"
                        className={edit ? 'pressed' : ''}
                    >
                    </Button>
                </>
            )}
            <TextField
                className="search-bar"
                value={searchBar}
                onChange={(e) => setSearchBar(e.target.value)}
                placeholder="Search..."
                variant="outlined"
                size="small"
                fullWidth
            />
            <Button
                onClick={toggleButtonSearch}
                variant={search ? 'contained' : 'outlined'}
                color="primary"
                className='search-button'
            >
                {/* TODO: Maybe need to change the format due to too long character */}
                {!search ? '试试筛选(*/ω＼*)' : 'ヾ(*ΦωΦ)ツ'}
            </Button>
            <HintSystem ref={hintRef}/>
        </div>
    );
}

export default Controls;